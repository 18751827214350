/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~angular2-draggable/css/resizable.min.css";

.delete-modal {
  align-items: center;
  justify-content: center;
  .modal-wrapper {
    --height: 80%;
    --width: 50%;
  }
}

.shipping-details-modal .modal-wrapper {
  --height: 80%;
  --width: 50%;
  background-color: red;
}

ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

ion-textarea { cursor: pointer }
ion-textarea:focus { cursor: auto }
textarea { cursor: pointer }
textarea:focus { cursor: auto }

.native-textarea {
  cursor: pointer !important;
}

.textarea-wrapper {
  cursor: pointer !important;
}

.fullscreen {
  --width:100% !important;
  --height:100% !important;
}